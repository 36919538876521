
/*About*/
.about-desc p {
  font-size: inherit;
  font-weight: inherit;
}
/* Rooms section */
.about-desc p:last-child {
  @apply mt-5;
}

@layer components {
  .form-input {
    border-radius: 5px;
    background-color: #e7e7e7;
    height: 39px;
    margin: 10px 0 10px 0;
    padding: 11px 313px 9px 16px;
    width: 100%;
  }
  .clear {
    width: 155px;
    height: 45px;
    margin: 20px 41px 0 380px;
    padding: 10px;
    text-align: center;
    border-radius: 22.5px;
    background-color: #5ecaed;
  }
  .clear-input {
    background: transparent;
  }
  .message-height {
    height: 130px;
  }
  .video-shape {
    width: 1448px;
    height: 100%;
    max-height: 850px;
    position: absolute;
    z-index: 1090;
    background-color: black;
    left: 50%;
    transform: translateX(-50%);
  }

  .img-wrap:hover .img-wrap_image {
        /*mix-blend-mode: screen;*/
        opacity: 0.7;
  }
  .img-wrap:hover .gradient {
    opacity: 0.6;
    background-position: 100% 100%;
  }

  .gradient {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    background: -moz-linear-gradient(-45deg, #000000 0%, #000000 25%, #1e539e 50%, #ff3083 75%, #7800a8 100%);
    background: -webkit-linear-gradient(-45deg, #000000 0%,#000000 25%,#1e539e 50%,#ff3083 75%,#7800a8 100%);
    background: linear-gradient(135deg, #000000 0%,#000000 25%,#1e539e 50%,#ff3083 75%,#7800a8 100%);
    background-size: 350% 350%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    color: #fff;
    cursor: pointer;
    transition: .5s all;
  }
}

/* Rooms section */
.rooms-item:first-child {
  margin-top: 0;
}
.rooms-item:nth-child(even) {
  flex-direction: row-reverse;
}
.rooms-item:nth-child(even) .item-right {
  margin-left: 0;
  margin-right: 0;
}
@media screen and (min-width: 1024px) {
  .rooms-item:nth-child(even) .item-right {
    margin-right: 64px;
  }
  .rooms-item:nth-child(even) .item-left .line {
    left: -40px;
  }
}
@media screen and (min-width: 1280px) {
  .rooms-item:nth-child(even) .item-right {
    margin-right: 144px;
  }
  .rooms-item:nth-child(even) .item-left .line {
    left: -80px;
  }
}

/* Gallery slider */
svg.arrow--disabled g {
  opacity: 0.3;
}

body {
  overscroll-behavior-y: none;
}

.scroll-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  will-change: transform;
}

/*Privacy*/
#privacy ul { 
    list-style-type: disc; 
    list-style-position: inside; 
 }
 #privacy ol { 
    list-style-type: decimal; 
    list-style-position: inside; 
 }
 #privacy ul ul, #privacy ol ul { 
    list-style-type: circle; 
    list-style-position: inside; 
    margin-left: 15px; 
 }
 #privacy ol ol, #privacy ul ol { 
    list-style-type: lower-latin; 
    list-style-position: inside; 
    margin-left: 15px; 
 }
