
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  font-weight: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
  margin: 0;
  padding: 0;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
img, svg, video, canvas,
audio, iframe, embed, object {
  display: block;
}
*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.default', currentColor);
}
.google-map * {
  border-style: none;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

@tailwind base;

@layer base {

  /* fonts Monserrat */
  @font-face {
    font-family: 'Montserrat';
    font-weight: normal;
    src: url(../assets/fonts/Montserrat.eot);
    src: url(../assets/fonts/Montserrat.eot?#iefix) format('embedded-opentype'),
          url(../assets/fonts/Montserrat.woff) format("woff"),
          url(../assets/fonts/Montserrat.woff2) format('woff2'),
          url(../assets/fonts/Montserrat.ttf) format('truetype');
  }
  /* fonts Inter */
  @font-face {
    font-family: 'Inter';
    font-weight: normal;
    src: url(../assets/fonts/Inter.eot);
    src: url(../assets/fonts/Inter.eot?#iefix) format('embedded-opentype'),
          url(../assets/fonts/Inter.woff) format("woff"),
          url(../assets/fonts/Inter.woff2) format('woff2'),
          url(../assets/fonts/Inter.ttf) format('truetype');
  }

  body {
    @apply bg-dark;
  }
} 

@tailwind components;
@tailwind utilities;
